'use client';

import { Button } from '@chakra-ui/react';
import { useDesignTokens } from '../providers/design_tokens_provider';

interface props {
  onContinue: () => void;
  loading: boolean;
}

const Continue = ({ onContinue, loading }: props) => {
  const tokens = useDesignTokens();

  return (
    <Button
      w='100%'
      colorScheme={tokens.button.secondary.scheme}
      variant={'outline'}
      onClick={() => onContinue()}
      isLoading={loading}
    >
      Continuar
    </Button>
  );
};

export default Continue;
