'use client';

import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/navigation';
import { oryAPI } from '../../api';
import Login from './login';

const loginUrl = () =>
  `${process.env.NEXT_PUBLIC_ORY_SDK_URL}/self-service/login/browser?return_to=${window.location.origin}`;

export default function OryLoginPage() {
  const router = useRouter();

  const { isLoading, data: authCtx } = useQuery({
    queryKey: ['oryLoginSession'],
    queryFn: () =>
      oryAPI
        .toSession()
        .then(async ({ data }) => {
          const logoutUrl = (await oryAPI.createBrowserLogoutFlow()).data
            .logout_url;
          return { active: data.active, logoutUrl };
        })
        .catch((err) => {
          console.error(err, err.stack);
          console.log(err.data);
          return { active: false, logoutUrl: null };
        }),
  });

  const redirectToLogin = () => {
    window.location.href = loginUrl();
  };

  const onSignIn = async () => {
    redirectToLogin();
  };

  const onSignOut = async () => {
    window.location.href = authCtx?.logoutUrl;
  };

  const onContinue = async () => {
    router.push('/professionals');
  };

  return (
    <Login
      onSignIn={onSignIn}
      onSignOut={onSignOut}
      onContinue={onContinue}
      isAuthenticated={authCtx?.active}
      loading={isLoading}
    />
  );
}
